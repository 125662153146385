<template>
  <div id="app">
    <ScreenTime  />
  </div>
</template>

<script>
import ScreenTime from "./components/ScreenTime.vue";

export default {
  name: "App",
  components: {
    ScreenTime
  }
};
</script>

<style>
@font-face {
  font-family: "Pangolin";
  src: url('./fonts/Pangolin-Regular.ttf');
  font-display: swap;
}

/* removes blue box on click */
input,
textarea,
button,
span,
div,
select,
a {
  -webkit-tap-highlight-color: rgba(0, 0, 0, 0);
}
button:focus {
  outline: 0;
}

body {
  margin: 0px;
  background: rgb(243, 131, 98);
  background: linear-gradient(-0deg, rgb(255, 96, 48), rgb(255, 212, 201));
  background-attachment: fixed;
}

body {
	-webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

#cookieBanner,
#app {
  background: transparent;
  font-family: "Pangolin", "Comic Sans MS", cursive, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: rgb(53, 53, 53);
  user-select: none;
  /* min-height: 900px; */
  position: static;
  overflow-x: hidden;
}
@media (max-width: 500px) {
  #app {
    /* min-height: 900px; */
    margin-top: 0px;
  }
}
@media (max-width: 400px) {
  #app {
    /* min-height: 950px; */
    margin-top: 0px;
  }
}

.totalScreenTime .span {
  font-size: 32px;
  line-height: 20px;
}


#cookie_munch_element {
  font-family: "Pangolin", "Comic Sans MS", cursive, sans-serif;
}

.cookiemunch_switch_title_container h2 {
    font-size: 16px !important;
}

#cookie_munch_element .cookie_munch_title_wrap h1 {
    font-size: 16px;
}

</style>
