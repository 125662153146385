<!-- Add "scoped" attribute to limit CSS to this component only -->
<style scoped lang="scss">
@import "@/sass/theme.scss";
</style>

<template>
  <div class="app-inner" @click.prevent="sound_alarm.stop(); alarmRunning = false;">
    <div class="main-wrapper ">
      <h1 v-if="showMainWrapper" class="main-title">Screen time converter</h1>
      <div v-if="showMainWrapper" class="time-mode-switch">
        <span @click="switch_earnTime()" :class="timemodeswitch_earn ? 'active': ''">
          Earn screen time
          <i class="fas fa-stopwatch"></i>
        </span>
        <span @click="switch_useTime()" :class="`${!timemodeswitch_earn ? 'active': ''} ${!canUseScreentime ? 'disabled': ''} ${shakePromptForUseScreenTime ? 'pulse': ''}`">
          Use screen time
          <i class="fas fa-hourglass-half"></i>
        </span>
      </div>
      <div :class="`main-content ${(showMainWrapper) ? `show-wrapper` : `hide-wrapper`}`">
        <!-- earn time -->

        <div v-show="timemodeswitch_earn">
          <div :class="`timer-container${(alarmRunning) ? ' shake-little': ''}`">
            <div :class="`timer-bg${(timerRunning) ? ' rotating' : ''} ${(alarmRunning) ? ' shake-little': ''}`"></div>
            <div :class="`timer-wrapper ${startStopValue}${timerwrapperfontsize}${(alarmRunning) ? ' shake-little': ''}`">
              <button class="settings-button rotate-hover" @click="settings()">
                Settings
                <i class="fas fa-cog"></i>
              </button>
              <h2>{{timerTimeValue}}</h2>
              <button v-show="!timerRunning" @click="startTimer()">
                Start
                <i class="icon fas fa-play-circle"></i>
              </button>
              <button v-show="timerRunning" @click="stopTimer()">
                Stop
                <i class="icon fas fa-stop-circle"></i>
              </button>
              <button v-show="resetAsk" class="clear-button" @click="resetTimerAsk()">
                Reset
                <i class="fas fa-times-circle"></i>
              </button>
              <button v-show="!resetAsk" class="clear-button shake-little-slow" @click="resetTimer()">
                 Sure
                <i class="fas fa-question-circle"></i>
              </button>
            </div>
            <div class="volume-buttons" @click="changeVolume()">
              <div v-show="volume === 0">
              <i  class="fas fa-volume-mute"></i>
              </div>
               <div v-show="volume === 1">
              <i  class="fas fa-volume-down"></i>
              </div>
              <span v-if="volume === 0">Tick tock off</span>
              <span v-if="volume === 1">Tick tock on</span>
            </div>
            <div class="alarm-buttons shake-little-hover" @click="changeVolume_bell()">
              <div v-show="volume_bell === 0" >
                <i class="icon far fa-bell"></i>
              </div>
              <div v-show="volume_bell === 1">
                <i  class="icon fas fa-bell"></i>
              </div>
              <span v-show="volume_bell === 0">Alarm bell off</span>
              <span v-show="volume_bell === 1">Alarm bell on</span>
            </div>
          </div>
          <div class="behavior">
            <div class="button" @click="removeMins()">
              <i class="far fa-sad-tear"></i>
            </div>
            <input
              id="BehaviorReadoutText"
              class="slider"
              @input="behaviourSlider($event)"
              :value="addedMins"
              type="range"
              :min="Number(0 - Number(BehaviorStep) - Number(BehaviorStep) - Number(BehaviorStep))"
              :max="Number(0 + Number(BehaviorStep) + Number(BehaviorStep) + Number(BehaviorStep))"
              :step="Number(BehaviorStep)"
            />
            <div class="button" @click="addMins()">
              <i class="far fa-smile-beam"></i>
            </div>
          </div>
              <h3
            class="behavior-text"
          ><label for="BehaviorReadoutText">Behavior {{ BehaviorReadoutText }}</label></h3>
          <!-- <div class="totalScreenTime-wrapper">
            <h3 class="totalScreenTime-label">Total screen time earned =</h3>
            <h1 class="totalScreenTime" v-html="totalScreenTime"></h1>
          </div>-->
        </div>

        <!-- use time -->

        <div v-show="!timemodeswitch_earn">
           <div :class="`timer-container${(alarmRunning) ? ' shake-little': ''}`">
            <div :class="`timer-bg${(timerRunning) ? ' rotating' : ''} ${(alarmRunning) ? ' shake-little': ''}`"></div>
            <div :class="`timer-wrapper ${startStopValue}${timerwrapperfontsize}${(alarmRunning) ? ' shake-little': ''}`">
              <button class="settings-button disabled">
                Settings
                <i class="fas fa-cog"></i>
              </button>
              <h2>{{timerTimeValue}}</h2>

              <button
                :class="hasScreenTime ? '':'disabled'"
                v-show="!timerRunning"
                @click="startTimer_countdown()"
              >
                Start
                <i class="icon fas fa-play-circle"></i>
              </button>

              <button v-show="timerRunning" @click="stopTimer()">
                Stop
                <i class="icon fas fa-stop-circle"></i>
              </button>

              <button class="clear-button disabled">
                Reset
                <i class="fas fa-times-circle"></i>
              </button>
            </div>
            <div class="volume-buttons" @click="changeVolume()">
              <div v-show="volume === 0">
              <i  class="fas fa-volume-mute"></i>
              </div>
               <div v-show="volume === 1">
              <i  class="fas fa-volume-down"></i>
              </div>
              <span v-if="volume === 0">Tick tock off</span>
              <span v-if="volume === 1">Tick tock on</span>
            </div>
            <div class="alarm-buttons shake-little-hover" @click="changeVolume_bell()">
              <div v-show="volume_bell === 0" >
                <i class="icon far fa-bell"></i>
              </div>
              <div v-show="volume_bell === 1">
                <i  class="icon fas fa-bell"></i>
              </div>
              <span v-show="volume_bell === 0">Alarm bell off</span>
              <span v-show="volume_bell === 1">Alarm bell on</span>
            </div>
          </div>
          <h2
            v-if="doNotification_blocked"
            class="info-text"
            style="max-width:500px; margin:32px auto;"
          >
            Notifications are blocked, try the
            <i class="fas fa-info-circle"></i> or
            <i class="fas fa-bell-slash"></i> icons in the address bar or&nbsp;
            <span class="link" @click="googleLink()">Google it.</span>
          </h2>

          <div v-else class="behavior disabled">
            <div class="button" @click="removeMins()">
              <i class="far fa-sad-tear"></i>
            </div>
            <input
              id="BehaviorReadoutText"
              class="slider"
              @input="behaviourSlider($event)"
              :value="addedMins"
              type="range"
              :min="Number(0 - Number(BehaviorStep) - Number(BehaviorStep) - Number(BehaviorStep))"
              :max="Number(0 + Number(BehaviorStep) + Number(BehaviorStep) + Number(BehaviorStep))"
              :step="Number(BehaviorStep)"
            />
            <div class="button" @click="addMins()">
              <i class="far fa-smile-beam"></i>
            </div>
          </div>
          <h3
            v-if="!doNotification_blocked"
            class="behavior-text disabled"
          ><label for="BehaviorReadoutText">Behavior {{ BehaviorReadoutText }}</label></h3>
          <!-- <div class="totalScreenTime-wrapper">
            <h3 class="totalScreenTime-label">Screen time remaining =</h3>
            <h1 class="totalScreenTime" v-html="totalScreenTime"></h1>
          </div>-->
        </div>

        <div class="info-links">
          <button class="settings-button-in-settings" @click="moreInfo()">
            More info
            <i class="fas fa-info-circle"></i>
          </button>
          <button class="settings-button-in-settings" @click="contact()">
            Contact
            <i class="fas fa-at"></i>
          </button>
        </div>

        <!-- social -->
        <div v-show="online" class="social-media-links">
          <!-- facebook -->
          <div style="display: none;" class="fb-share-button" data-href="https://screentimeconverter.com/" data-layout="button" data-size="large">
            <a target="_blank" href="https://www.facebook.com/sharer/sharer.php?u=https%3A%2F%2Fscreentimeconverter.com%2F&amp;src=sdkpreparse" class="fb-xfbml-parse-ignore">Share</a>
          </div>
          <!-- twitter -->
          <div style="display: none;" class="twitter-share-button-wrapper">
           <a
              href="https://twitter.com/share?ref_src=twsrc%5Etfw"
              class="twitter-share-button"
              data-size="large"
              data-text="Here&#39;s a little app to help with screen time. https://pic.twitter.com/n717qDqCig"
              data-url="http://screentimeconverter.com"
              data-via="Dunks1980"
              data-hashtags="#pwa #pwaapp #parenting #kids #kidsathome"
              data-show-count="false"
            >Tweet</a>
          </div>
        </div>

      </div>
      <div :class="`main-content ${showSettingsWrapper ? `show-wrapper` : `hide-wrapper`}`">
        <h1 class="main-title">Settings</h1>
        <br />
      

        <h2 class="info-text">
          <label for="minutesRatio">30 minutes of screen time for </label>
          <select id="minutesRatio" v-model="minutesRatio" class="select-options">
            <option value="1">30 mins</option>
            <option value="2">1 hour</option>
            <option value="4">2 hours</option>
            <option value="6">3 hours</option>
            <option value="8">4 hours</option>
            <option value="10">5 hours</option>
            <option value="12">6 hours</option>
          </select> of work.
        </h2>

        <h2 class="info-text">
          <label for="BehaviorStep">Behavior slider steps adjustment = </label>
          <select id="BehaviorStep" v-model="BehaviorStep" class="select-options">
            <option value="5">5 mins</option>
            <option value="10">10 mins</option>
            <option value="15">15 mins</option>
            <option value="20">20 mins</option>
          </select>
        </h2>

        <br />

        <button class="settings-button-in-settings" @click="settings()">
          Go back
          <i class="fas fa-arrow-circle-left"></i>
        </button>

  <button class="settings-button-in-settings" @click="defaults()">
          Defaults
          <i class="fas fa-undo-alt"></i>
        </button>

        <button class="settings-button-in-settings" @click="applySettings()">
          Apply these settings
          <i class="fas fa-check-circle"></i>
        </button>
      </div>

      <div :class="`main-content ${showMoreInfoWrapper ? `show-wrapper` : `hide-wrapper`}`">
        <h1 class="main-title">More info</h1>
        <br />

        <h2
          class="info-text"
        >Screen time can cause problems when it comes to getting kids to do other things, like tidying their room, doing homework or going to bed.</h2>

        <h2
          class="info-text"
        >I have designed and developed this app to try and help by converting time spent on things they winge and whine about doing into screen time.</h2>

        <h2 class="info-text">
          Manage the settings to tailor the screen time as you see fit.
          The counter will keep going if you leave the page then come back.
        </h2>

        <h2 class="info-text" v-if="!pwaAccepted">
          <span>
            You can also
            <span class="link" @click="installer()">install the app</span> to use it offline.
          </span>
        </h2>

        <h2 class="info-text">
          If you have any issues or ideas please
          <span class="link" @click="contact()">get in touch</span>.
        </h2>

        <h2 class="info-text policy">
          <span style="margin: 0 15px;" class="link" @click="privacy()">Privacy policy</span>
          <span style="margin: 0 15px;" class="link" @click="cookie()">Cookie policy</span>
        </h2>

        <br />
        <br />

        <button class="settings-button-in-settings" @click="moreInfo()">
          Go back
          <i class="fas fa-arrow-circle-left"></i>
        </button>

        <button
          :class="`settings-button-in-settings ${!timemodeswitch_earn ? 'disabled' : ''}`"
          @click="moreInfo(); settings()"
        >
          Settings
          <i class="fas fa-cog"></i>
        </button>

        <button
          class="settings-button-in-settings"
          @click="
        Notification_test(`Notification test`);"
        >
          Notification test
          <i class="fas fa-cog"></i>
        </button>

        <h2
          v-show="doNotification_blocked"
          class="info-text"
          style="max-width:500px; margin:32px auto;"
        >
          Notifications are blocked, try the
          <i class="fas fa-info-circle"></i> or
          <i class="fas fa-bell-slash"></i> icons in the address bar or&nbsp;
          <span class="link" @click="googleLink()">Google it.</span>
        </h2>
      </div>
    </div>
  </div>
</template>

<script>
const moment = require("moment"); // require
const localforage = require("localforage");
const Notify = require("notifyjs");
import { Howl } from "howler";

moment().format();
var store = localforage.createInstance({
  name: "earn-screen-time"
});
export default {
  name: "ScreenTime",
  components: {
  },
  props: {
    msg: String
  },
  data() {
    return {
      online: false,
      worker: undefined,
      doNotification: false,
      doNotification_blocked: false,
      timemodeswitch_earn: true,
      installer: undefined,
      pwaAccepted: false,
      totalScreenTime: "None yet",
      noScreenTime: "None yet",
      secondsGained: 0,
      dateFomat: "DD-MM-YYYY HH:mm:ss",
      dummyStartTime: "18-05-2020 00:00",
      timerTimeValue: "00:00:00",
      timerRunning: false,
      startStopValue: "Start",
      interval: false,
      addedMins: 0,
      BehaviorReadoutText: "just ok",
      timerwrapperfontsize: "",
      showMainWrapper: false,
      showSettingsWrapper: false,
      showMoreInfoWrapper: false,
      BehaviorStep: 10,
      minutesRatio: 1,
      hasScreenTime: undefined,
      sound_clock: undefined,
      sound_silence: undefined,
      sound_alarm: undefined,
      sound_ding: undefined,
      volume: 1,
      volume_bell: 1,
      current_sound: "clock",
      alarmRunning: false,
      resetAsk: true,
      setTimeout_resetAsk: undefined
    };
  },
  mounted() {
    this.restartTimer();
    this.restartSettings();
    this.restartAddedMins();
    this.workOutReadOut();
    this.BehaviorReadout();
    this.timerwrapperfontsize = " timer-wrapper-font-size";

    this.worker = new Worker("js/webworker_interval.js");
    this.sound_clock = new Howl({
      src: "sounds/Clock_ticking.mp3",
      volume: 0.3,
      loop: true
    });
    this.sound_silence = new Howl({
      src: "sounds/empty_loop_for_js_performance.mp3",
      volume: 0.5,
      loop: true
    });
    this.sound_alarm = new Howl({
      src: "sounds/BellAlarmClock.mp3",
      volume: 0.5,
      loop: false
    });
    this.sound_ding = new Howl({
      src: "sounds/ding.mp3",
      volume: 1,
      loop: false
    });

    // startup
    let vm = this;
    vm.showMainWrapper = true;

   window.cookiemunch_options = {
        settings: {
          cookie_image: 'https://unpkg.com/@dunks1980/cookiemunch/cookiemunch_flat_light.svg'
        },
        cookies: [
          {
            id: "gtm",
            name: "google Analytics",
            used_for: "To collect analyitical data and monitor performance.",
            url_text: "Privacy & Terms",
            url: "https://policies.google.com/privacy",
            accepted_function: cookiemunch_ga,
            declined_function: cookiemunch_ga_off
          },
          {
            id: "twitter",
            name: "Twitter",
            used_for: "For sharing across the Twitter social media platform.",
            url_text: "Privacy & Terms",
            url: "https://twitter.com/en/privacy",
            accepted_function: twitter,
            declined_function: twitter_off
          },
          {
            id: "facebook",
            name: "Facebook",
            used_for: "For sharing across the Facebook social media platform.",
            url_text: "Privacy & Terms",
            url: "https://www.facebook.com/policy.php",
            accepted_function: facebook,
            declined_function: facebook_off
          }
        ],
        required_cookies: [
            {
                name: "Cloudflare",
                used_for: "Cookie is set by Cloudflare to speed up their load times and for threat defense services. It does not collect or share user identification information.",
                url_text: "Privacy policy",
                url: "https://www.cloudflare.com/en-gb/privacypolicy/"
            },
            {
              name: "Cookiemunch",
              used_for: "Cookies are set by Cookiemunch to remeber users cookie preferences. It does not collect or share user identification information.",
              url_text: "More infomation",
              url: "https://cookiemunch.dunks1980.com/"
            }
        ]
      };



      // example (function needs to be globally available)
      function cookiemunch_ga() {
        // Google Analytics
        console.log("adding Google Analytics");
        window['ga-disable-UA-48843225-3'] = false;
        window.dataLayer = window.dataLayer || [];
        function gtag() {
          window.dataLayer.push(arguments);
        }
        gtag('js', new Date());
        gtag('config', 'UA-48843225-3');
      }

      function cookiemunch_ga_off() {
        // Google Analytics
        console.log("removing Google Analytics");
        window['ga-disable-UA-48843225-3'] = true;
      }

      function twitter() {
        console.log("adding twitter");
        var script = document.createElement("script");
        script.setAttribute("async", true);
        script.setAttribute("defer", true);
        script.setAttribute("charset", "utf-8");
        script.setAttribute("src", "https://platform.twitter.com/widgets.js");
        document.body.appendChild(script);
        if (document.querySelector(".twitter-share-button-wrapper")) {
          document.querySelector(".twitter-share-button-wrapper").setAttribute("style", null);
        }
      }
      function twitter_off() {
        if (document.querySelector(".twitter-share-button-wrapper")) {
          document.querySelector(".twitter-share-button-wrapper").setAttribute("style", "display: none;");
        }
      }

      function facebook() {
        console.log("adding facebook");
        var script = document.createElement("script");
        script.setAttribute("async", true);
        script.setAttribute("defer", true);
        script.setAttribute("charset", "utf-8");
        script.setAttribute("src", "https://connect.facebook.net/en_US/sdk.js#xfbml=1&version=v7.0");
        document.body.appendChild(script);
        if (document.querySelector(".fb-share-button")) {
          document.querySelector(".fb-share-button").setAttribute("style", null);
        }
        console.log("facebook function");
      }
      function facebook_off() {
        if (document.querySelector(".fb-share-button")) {
          document.querySelector(".fb-share-button").setAttribute("style", "display: none;");
        }
        console.log("facebook function off");
      }







    window.cookiemunch(window.cookiemunch_options, false);
  },
  computed: {
    // a computed getter
    canUseScreentime: function () {
      let totalScreenTimeOffset = moment(this.dummyStartTime, this.dateFomat)
        .add(this.secondsGained / this.minutesRatio, "seconds")
        .add(this.addedMins, "minutes");

      let total = totalScreenTimeOffset.diff(
        moment(this.dummyStartTime, this.dateFomat),
        "seconds"
      );
      if (total > 0) {
        return true; 
      } else {
         return false; 
      }
    },
    shakePromptForUseScreenTime: function() {
      let totalScreenTimeOffset = moment(this.dummyStartTime, this.dateFomat)
      .add(this.secondsGained / this.minutesRatio, "seconds")
      .add(this.addedMins, "minutes");
      let total = totalScreenTimeOffset.diff(
        moment(this.dummyStartTime, this.dateFomat),
        "seconds"
      );
      if (total > 0 && !this.timerRunning && this.timemodeswitch_earn) { 
        return true; 
      } else {
        return false; 
      }
    }
  },

  methods: {
    switch_earnTime() {
      store.setItem("timemodeswitch_earn", true);
      if (!this.timemodeswitch_earn) {
        this.stopTimer();
      }
      this.timemodeswitch_earn = true;
      this.workOutReadOut();
    },
    switch_useTime() {
      this.getNotificationPermissions();
      store.setItem("timemodeswitch_earn", false);
      if (this.timemodeswitch_earn) {
        this.stopTimer();
      }
      this.timemodeswitch_earn = false;
      this.workOutReadOut();
    },
    restartAddedMins() {
      let vm = this;
      store.getItem("timeer-addedMins").then(addedMins => {
        //console.log(addedMins);
        if (addedMins) {
          vm.addedMins = addedMins;
          vm.BehaviorReadout();
        }
      });
    },
    restartSettings() {
      let vm = this;
      store.getItem("settings-ratio").then(ratio => {
        //console.log(ratio);
        if (ratio) {
          vm.minutesRatio = ratio;
          vm.BehaviorReadout();
        }
      });
      store.getItem("settings-BehaviorStep").then(BehaviorStep => {
        //console.log(BehaviorStep);
        if (BehaviorStep) {
          vm.BehaviorStep = BehaviorStep;
          vm.BehaviorReadout();
        }
      });
      store.getItem("timemodeswitch_earn").then(timemodeswitch_earn => {
        if (timemodeswitch_earn !== null) {
          vm.timemodeswitch_earn = JSON.parse(timemodeswitch_earn);
          if (!vm.timemodeswitch_earn) {
            vm.switch_useTime();
          } else {
            vm.switch_earnTime();
          }
        } else {
          vm.switch_earnTime();
        }
      });
      store.getItem("pwaAccepted").then(pwaAccepted => {
        if (pwaAccepted) {
          vm.pwaAccepted = pwaAccepted;
        }
      });
      store.getItem("doNotification").then(doNotification => {
        if (doNotification !== null) {
          vm.doNotification = JSON.parse(doNotification);
        }
      });
      store.getItem("volume").then(volume => {
        if (volume !== null) {
          vm.volume = JSON.parse(volume);
        }
      });
      store.getItem("volume_bell").then(volume_bell => {
        if (volume_bell !== null) {
          vm.volume_bell = JSON.parse(volume_bell);
        }
      });
    },
    settings() {
      this.showMainWrapper = !this.showMainWrapper;
      this.showSettingsWrapper = !this.showSettingsWrapper;
    },
    applySettings() {
      this.showMainWrapper = !this.showMainWrapper;
      this.showSettingsWrapper = !this.showSettingsWrapper;
      store.setItem("settings-ratio", Number(this.minutesRatio));
      store.setItem("settings-BehaviorStep", Number(this.BehaviorStep));
      this.workOutReadOut();
      this.BehaviorReadout();
    },
    defaults() {
      this.addedMins = 0;
      this.BehaviorStep = 10;
      this.minutesRatio = 1;
    },
    moreInfo() {
      this.showMainWrapper = !this.showMainWrapper;
      this.showMoreInfoWrapper = !this.showMoreInfoWrapper;
    },
    contact() {
      window.location.href = "mailto:info@screentimeconverter.com";
    },
    privacy() {
      window.location.href = "/privacy";
    },
    cookie() {
      window.location.href = "/cookie-policy";
    },
    googleLink() {
      window.open('https://www.google.com/search?q=how+to+allow+notifications');
    },
    restartTimer() {
      let vm = this;
      store
        .getItem("timeer-secondsGained")
        .then(secondsGained => {
          vm.secondsGained = secondsGained;
          vm.workOutReadOut();
          vm.BehaviorReadout();
        })
        .then(() => {
          vm.timerTimeValue = moment(vm.dummyStartTime, vm.dateFomat)
            .add(vm.secondsGained, "seconds")
            .format("HH:mm:ss");
        })
        .then(() => {
          store.getItem("timeer-timerRunning").then(timerRunning => {
            console.log(timerRunning);
            if (timerRunning === true) {
              // get the time diff from last entry to storage:
              store.getItem("last-entry").then(lastentry => {
                let secondsAwayFromPage = moment().diff(
                  moment(lastentry, this.dateFomat),
                  "seconds"
                );
                console.log("secondsAwayFromPage");
                console.log(secondsAwayFromPage);
                let seconds = secondsAwayFromPage;
                console.log(seconds);
                if (vm.timemodeswitch_earn) {
                  vm.secondsGained = Number(vm.secondsGained) + Number(seconds);
                  vm.startTimer();
                  vm.workOutReadOut();
                } else {
                  vm.secondsGained =
                    Number(vm.secondsGained) -
                    Number(seconds) / this.minutesRatio;
                  vm.startTimer_countdown();
                  vm.workOutReadOut();
                }
              });
            } else {
              vm.workOutReadOut();
            }
          });
        });
    },
    performance_trick_start() {
      setTimeout(() => {
        if (this.volume === 0) {
          this.sound_silence.play();
          this.sound_clock.stop();
          this.current_sound = "silence";
        } else if (this.volume === 1) {
          this.sound_clock.play();
          this.sound_silence.stop();
          this.current_sound = "clock";
        }
      }, 800);
    },
    changeVolume() {
      if (this.volume === 0) {
        this.volume = 1;
      } else if (this.volume === 1) {
        this.volume = 0;
      }
      if (!this.timerRunning) {
        this.sound_clock.stop();
        this.sound_silence.stop();
        if (this.volume === 0) {
          this.current_sound = "silence";
        } else if (this.volume === 1) {
          //this.sound_clock.volume(0.3);
          this.current_sound = "clock";
        }
      } else {
        if (this.volume === 0) {
          if (this.current_sound === "clock") {
            this.sound_silence.play();
            this.sound_clock.stop();
          }
          this.current_sound = "silence";
        } else if (this.volume === 1) {
          //this.sound_clock.volume(0.3);
          if (this.current_sound === "silence") {
            this.sound_clock.play();
            this.sound_silence.stop();
          }
          this.current_sound = "clock";
        }
      }
      console.log("volume: " + this.volume);
      store.setItem("volume", this.volume);
    },
    changeVolume_bell() {
      if (this.volume_bell === 0) {
        this.volume_bell = 1;
      } else {
        this.volume_bell = 0;
      }
      store.setItem("volume_bell", this.volume_bell);
    },
    startTimer() {
      this.timerRunning = true;
      store.setItem("timeer-timerRunning", this.timerRunning);

      this.worker.postMessage({ time: 1000 });

      this.worker.onmessage = () => {
        this.secondsGained++;
        this.workOutReadOut();
        store.setItem("timeer-secondsGained", this.secondsGained);
        store.setItem("last-entry", moment().format(this.dateFomat));
      };
      this.performance_trick_start();
      this.startStopValue = "Stop";
    },

    startTimer_countdown() {
      this.timerRunning = true;
      store.setItem("timeer-timerRunning", this.timerRunning);
      let timerStared = true;

      this.worker.postMessage({ time: 1000 / this.minutesRatio });

      this.worker.onmessage = () => {
        this.secondsGained--;
        if (this.timerTimeValue === "00:00:01") {
          if (timerStared === true) {
            this.Notification_screenTimeFinished("Screen time is over!");
            timerStared = false;
          }
        }
        let testIfBefore = moment(this.dummyStartTime, this.dateFomat)
          .add(this.secondsGained / this.minutesRatio, "seconds")
          .add(this.addedMins, "minutes");
        if (
          this.timerTimeValue === "00:00:00" ||
          moment(testIfBefore).isBefore(
            moment(this.dummyStartTime, this.dateFomat)
          )
        ) {
          this.stopTimer();
          this.timerTimeValue = "00:00:00";
          this.startStopValue = "Start";
          this.secondsGained = 0;
        }
        this.workOutReadOut();
        store.setItem("timeer-secondsGained", this.secondsGained);
        store.setItem("last-entry", moment().format(this.dateFomat));
      };
      this.performance_trick_start();
      this.startStopValue = "Stop";
      this.getNotificationPermissions();
    },
    getNotificationPermissions() {
      let vm = this;

      Notify.requestPermission(onPermissionGranted, onPermissionDenied);

      function onPermissionGranted() {
        console.log("Permission has been granted by the user");
        vm.doNotification = true;
        store.setItem("doNotification", true);
        vm.doNotification_blocked = false;
      }

      function onPermissionDenied() {
        console.warn("Permission has been denied by the user");
        vm.doNotification = false;
        store.setItem("doNotification", false);
        vm.doNotification_blocked = true;
      }
    },

    stopTimer() {
      this.sound_silence.stop();
      this.sound_clock.stop();

      this.worker.postMessage({ time: null });

      //clearInterval(this.interval);
      this.startStopValue = "Start";
      this.timerRunning = false;
      store.setItem("timeer-secondsGained", this.secondsGained);
      store.setItem("timeer-timerRunning", this.timerRunning);
    },
    resetTimerAsk() {
      this.resetAsk = false;
      clearTimeout(this.setTimeout_resetAsk); 
      this.setTimeout_resetAsk = setTimeout(() => {
         this.resetAsk = true;
      },3000);
    },
    resetTimer() {
      this.addedMins = 0;
      this.BehaviorReadoutText = "just ok";
      this.secondsGained = 0;
      this.timerTimeValue = "00:00:00";
      store.setItem("timeer-secondsGained", 0);
      store.setItem("last-entry", moment().format(this.dateFomat));
      store.setItem("timeer-addedMins", 0);
      this.BehaviorReadout();
      this.resetAsk = true;
    },
    removeMins() {
      if (
        this.addedMins >
        0 -
          Number(this.BehaviorStep) -
          Number(this.BehaviorStep) -
          Number(this.BehaviorStep)
      ) {
        this.addedMins = this.addedMins - Number(this.BehaviorStep);
      }
      this.workOutReadOut();
      this.BehaviorReadout();
      store.setItem("timeer-addedMins", this.addedMins);
    },
    addMins() {
      if (
        this.addedMins <
        0 +
          Number(this.BehaviorStep) +
          Number(this.BehaviorStep) +
          Number(this.BehaviorStep)
      ) {
        this.addedMins = this.addedMins + Number(this.BehaviorStep);
      }
      this.workOutReadOut();
      this.BehaviorReadout();
      store.setItem("timeer-addedMins", this.addedMins);
    },
    behaviourSlider(e) {
      //console.log(e.target.value);
      //console.log("test");
      this.addedMins = Number(e.target.value);
      this.workOutReadOut();
      this.BehaviorReadout();
      store.setItem("timeer-addedMins", this.addedMins);
    },

    workOutReadOut() {
      let totalScreenTimeOffset;

      totalScreenTimeOffset = moment(this.dummyStartTime, this.dateFomat)
        .add(this.secondsGained / this.minutesRatio, "seconds")
        .add(this.addedMins, "minutes");

      let total = totalScreenTimeOffset.diff(
        moment(this.dummyStartTime, this.dateFomat),
        "seconds"
      );

      if (total >= 0) {
        this.timerTimeValue = moment(this.dummyStartTime, this.dateFomat)
          .add(this.secondsGained / this.minutesRatio, "seconds")
          .add(this.addedMins, "minutes")
          .format("HH:mm:ss");
      } else {
        this.timerTimeValue = moment(this.dummyStartTime, this.dateFomat)
          .subtract(this.secondsGained / this.minutesRatio, "seconds")
          .subtract(this.addedMins, "minutes")
          .format("[-]HH:mm:ss");
      }

      if (
        this.timerTimeValue.indexOf("00:00:00") > -1 ||
        this.timerTimeValue.indexOf("-") > -1
      ) {
        this.hasScreenTime = false;
      } else {
        this.hasScreenTime = true;
      }

      store.setItem("timeer-secondsGained", this.secondsGained);
    },

    BehaviorReadout() {
      let forReturn;
      let step = Number(this.BehaviorStep);
      let mins = this.addedMins;

      let step_0 = 0 - step - step - step;
      let step_1 = 0 - step - step;
      let step_2 = 0 - step;
      let step_3 = 0; // center default
      let step_4 = 0 + step;
      let step_5 = 0 + step + step;
      let step_6 = 0 + step + step + step;

      let msg_0 = "Call Supernanny!!" + " " + mins + " mins";
      let msg_1 = "appalling!" + " " + mins + " mins";
      let msg_2 = "naughty" + " " + mins + " mins";
      let msg_3 = "reasonable"; // center default
      let msg_4 = "good" + " +" + mins + " mins";
      let msg_5 = "very good!" + " +" + mins + " mins";
      let msg_6 = "excellent!!" + " +" + mins + " mins";

      if (mins < step_0) {
        forReturn = msg_0;
      } else if (mins >= step_0 && mins < step_1) {
        forReturn = msg_0;
      } else if (mins >= step_1 && mins < step_2) {
        forReturn = msg_1;
      } else if (mins >= step_2 && mins < step_3) {
        forReturn = msg_2;
      } else if (mins >= step_3 && mins < step_4) {
        forReturn = msg_3; // center default
      } else if (mins >= step_4 && mins < step_5) {
        forReturn = msg_4;
      } else if (mins >= step_5 && mins < step_6) {
        forReturn = msg_5;
      } else if (mins >= step_6) {
        forReturn = msg_6;
      }
      this.BehaviorReadoutText = forReturn;
    },
    Notification_test(msg) {
      function fireNotification() {
        navigator.serviceWorker.ready.then(function(registration) {
          registration.showNotification("Screen time converter", {
            body: msg,
            notifyShow: onNotifyShow
          });
        });
      }
      navigator.serviceWorker.register("js/sw.js");
      this.getNotificationPermissions();
      if (this.doNotification) {
        fireNotification();
        this.doNotification_blocked = false;
      } else {
        this.doNotification_blocked = true;
      }
      function onNotifyShow() {
        //console.log("notification was shown!");
      }
    },
    Notification_screenTimeFinished(msg) {
      function fireNotification() {
        navigator.serviceWorker.ready.then(function(registration) {
          registration.showNotification("Screen time converter", {
            body: msg,
            notifyShow: onNotifyShow
          });
        });
      }

      navigator.serviceWorker.register("js/sw.js");

      this.getNotificationPermissions();

      if (this.doNotification) {
        fireNotification();
        this.doNotification_blocked = false;
      } else {
        this.doNotification_blocked = true;
      }

      if (this.volume_bell === 1) {
        this.sound_alarm.play();
        this.alarmRunning = true;
        setTimeout(() => {
           this.alarmRunning = false;
           this.sound_alarm.stop();
           this.timemodeswitch_earn = true;
        },5000);
      } else {
        this.sound_ding.play();
        this.timemodeswitch_earn = true;
      }
      this.timerRunning = false;
      this.startStopValue = "Start";
      this.sound_clock.stop();
      this.sound_silence.stop();

      function onNotifyShow() {
        //console.log("notification was shown!");
      }
    },
  },
  created() {
    let vm = this;
    let installPrompt;
    window.addEventListener("beforeinstallprompt", e => {
      e.preventDefault();
      installPrompt = e;
    });
    this.installer = () => {
      installPrompt.prompt();
      installPrompt.userChoice.then(result => {
        if (result.outcome === "accepted") {
          console.log("user accepted");
          vm.pwaAccepted = true;
          store.setItem("pwaAccepted", true);
        } else {
          console.log("user denied");
        }
        installPrompt = null;
      });
    };

    window.addEventListener('online',  updateOnlineStatus);
    window.addEventListener('offline', updateOnlineStatus);

    function updateOnlineStatus() {
      var condition = navigator.onLine ? true : false;
      vm.online = condition;
    }
    updateOnlineStatus();
  }
};
</script>


